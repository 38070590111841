import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import './fonts.css';
import './App.css';
import Lottie from "lottie-react";
import signatureAnimation1 from "./lottie1.json";

function Impressum() {
  let txta0 = "Coach für";
  let txta1 = "führungskräfte und";
  let txta2 = "privatpersonen";
  txta0 = txta0.toUpperCase();
  txta1 = txta1.toUpperCase();
  txta2 = txta2.toUpperCase();
  return (
    <div>
      <div id="claudiaLogo" className="ce"><Lottie animationData={signatureAnimation1} loop={true} style={{marginTop: '-20%', maxHeight: '1000px'}} /></div>
      <div className="ce lh25" id="fsp"><span className="alth4 bookFont">{txta0} <br />{txta1} <br />{txta2}</span></div>
      <div className="spacerDesktop150px"></div>
      <div className="whiteBox">
        <Row className="noRightPad">
          <Col>
            <div className="spacerDesktop70px"></div>
            <div className="male12pr">
              <p>
                <span className="alth4 bookFont">IMPRESSUM</span>
                <br />
                <span className="alth2 bookFont">
                  Claudia Kramlik <br />
                  Radetzkystraße 29 <br />
                  6850 Dornbirn <br />
                  <br />
                  coach@claudiakramlik.at <br />
                  +43 664 9212545
                </span>
              </p>
            </div>
          </Col>
          <Col className="noRightPad">
            <div className="spacerDesktop70px"></div>
            <div className="male12pr mari12pr">
              <p>
                <span className="altp bookFont">
                  Berufsbezeichnung: Diplom-Lebensberaterin <br />
                  Unternehmensgegenstand: Lebens- und Sozialberatung <br />
                  <br />
                  UID-Nummer: ATU 80907324 <br />
                  Mitglied der Wirtschaftskammer Vorarlberg, Österreich <br />
                  Fachgruppe Personenberatung und Personenbetreuung <br />
                  Gewerbebehörde: BH Dornbirn <br />
                  <br />
                  Anwendbare Rechtsvorschriften und Zugang Berufsrecht: <br />
                  Gewerbeordnung (BGBl 194/1994); Verordnung des Bundesministeriums für <br />
                  wirtschaftliche Angelegenheiten über Standes- und Ausübungsregeln für das Gewerbe der Lebens- und Sozialberatung (BGBL II 260/1998) abrufbar unter: <br />
                  ↘ <a href="https://www.ris.bka.gv.at" rel="noreferrer" target="_blank">www.ris.bka.gv.at</a> <br />
                  <br />
                  Website Credits <br />
                  <br />
                  Text, Konzept: Simon Bleil,  ↘ <a href="https://simonbleil.com" rel="noreferrer" target="_blank">simonbleil.com</a> <br />
                  Video, Fotografie: Christoph Skovic,  ↘ <a href="https://christophskofic.com" rel="noreferrer" target="_blank">christophskofic.com</a> <br />
                  Branding, Webdesign: Brini Fetz,  ↘ <a href="https://brinifetz.at" rel="noreferrer" target="_blank">brinifetz.at</a> <br />
                  Programmierung: Balázs Viktor Hende
                </span>
              </p>
            </div>
          </Col>
        </Row>
        <hr />
        <Row className="noRightPad">
          <Col>
            <div className="spacerDesktop70px"></div>
            <div className="male12pr">
              <p>
                <span className="altp bookFont">
                  Datenschutzerklärung <br />
                  <br />
                  Der Schutz eurer persönlichen Daten ist mir ein besonderes Anliegen. Ich verarbeite eure Daten daher aus­schließ­lich auf Grundlage der gesetzlichen Bestimmungen (DSGVO, TKG 2003). <br />
                  <br />
                  In dieser ↘ Anlage zur Daten­schutzerklärung informiere ich euch ausführlich über die Daten­ver­arbeitung im Rahmen einer Beratung. <br />
                  <br />
                  Kontakt mit mir <br />
                  <br />
                  Wenn ihr per E-Mail Kontakt mit mir aufnehmt, werden eure an­gegebenen Daten zwecks Be­arbeitung der Anfrage und für den Fall von Anschluss­fragen sechs Monate bei mir ge­­speich­ert. Diese Daten gebe ich nicht ohne eure Ein­willigung weiter. <br />
                  <br />
                  Eure Rechte <br />
                  <br />
                  Euch stehen bezüglich eurer bei mir gespeicherten Daten grundsätzlich die Rechte auf Auskunft, Bericht­igung, Lösch­ung, Einschränkung, Daten­über­trag­barkeit, Wider­ruf und Wider­spruch zu. Wenn ihr glaubt, dass die Ver­arbeitung eurer Daten gegen das Datenschutz­recht verstößt oder eure daten­­schutz­­recht­lichen An­sprüche sonst in einer Weise verletzt worden sind, könnt ihr euch bei der Daten­schutz­­behörde oder direkt bei mir per E-Mail be­schweren.
                </span>
                <div className="spacerDesktop70px"></div>
              </p>
            </div>
          </Col>
          <Col className="noRightPad">
            <div className="spacerDesktop70px"></div>
            <div className="male12pr mari12pr">
              <p>
                <span className="altp bookFont">
                  Haftung für Inhalte <br />
                  <br />
                  Die Inhalte dieser Webseite werden ständig weiterent­wickelt. Leider kann ich keine Haftung für die Kor­rektheit aller Inhalte auf dieser Webseite übernehmen, speziell für jene die seitens Dritter bereit­gestellt wurden. <br />
                  <br />
                  Haftung für Links <br />
                  <br />
                  Diese Webseite enthält Links zu anderen Webseiten für deren Inhalt ich nicht verantwortlich bin. Haftung für verlinkte Websites besteht laut § 17 ECG für mich nicht, da ich keine Kenntnis rechtswidriger Tätigkeiten hatte und habe, mir solche Rechtswidrigkeiten auch bisher nicht auf­gefallen sind und ich Links sofort entfernen würden, wenn mir Rechts­widrigkeiten bekannt werden. Wenn Ihnen problematische oder rechts­­widrige Inhalte oder Links auf dieser Website auffallen, bitte ich euch mich zu kontaktieren. <br />
                  <br />
                  Urheberrecht<br />
                  <br />
                  Die Inhalte, Bilder, Fotos und Grafiken auf dieser Webseite sind urheber-rechtlich geschützt. Der Inhalt dieser Website darf nicht zu kommerz­iellen Zwecken kopiert, verbreitet, verändert oder Dritten zugänglich gemacht werden. Kein Teil der Seite darf in irgend­einer Form ohne schriftliche Genehmigung reproduziert oder unter Ver­wendung elektronischer Systeme ver­arbeitet, verviel­fältigt oder sonst wie ver­breitet werden.
                </span>
                <div className="spacerDesktop70px"></div>
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <div className="spacerDesktop150px"></div>
      <div className="ce lh25"><span className="alth4 bookFont">{txta0} <br />{txta1} <br />{txta2}</span></div>
      <div className="spacerDesktop150px"></div>
    </div>
  );
}

export default Impressum;